html,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* VARIABLES */
:root {
  --success: #00c781;
  --error: #c62637;
  --black: #333;
  --dark-grey: #686868;
  --light-grey: #bebebe;
  --white: #fff;
}

header {
  display: grid;
  grid-template-columns: 150px 1fr 120px 120px;
  position: absolute;
  top: 0;
  height: 65px;
  width: calc(100vw - 30px);
  flex-direction: row;
  justify-items: end;
  align-items: center;
  padding: 0 15px;
}

header a.link-specification {
  grid-column: 3 / 4;
}

header a.link-repository {
  grid-column: 4 / 5;
  margin-right: 10px;
}

header a span {
  margin-left: 5px;
}

@media only screen and (max-width: 600px) {
  header a.link-specification,
  header a.link-repository {
    display: none;
  }

  a:first-child,
  .spacer:first-child {
    display: none;
  }

  section {
    margin-top: 120px;
  }
}

footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--black);
  height: 35px;
  color: var(--light-grey);
  width: calc(100% - 20px);
  height: 28px;
  bottom: 0px;
  position: absolute;
  padding-right: 20px;
}

footer .spacer {
  margin: 0px 10px;
}

main {
  margin-top: 65px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.success {
  color: var(--success);
}
.error {
  color: var(--error);
}

.form__label-editor {
  display: block;
}

.form__label-external {
  display: none;
}

span.response-message {
  margin-left: 8px;
}

footer a {
  text-decoration: underline;
}

header a,
header a:hover,
header a:active {
  color: var(--black);
}

footer a,
footer a:hover,
footer a:active {
  color: var(--white);
}

/* STAC TEXT EDITOR SECTION USED IN StacValidatorText */
.validation-text-editor {
  display: grid;
  grid-template-columns: 650px 1fr;
}

.not-visible {
  display: none !important;
}

/* Layout for button/input  elements in StacValidatorText and StacValidatorUrl */
section.validation-pane {
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-row-gap: 70px;
  overflow-y: scroll;
}

.validation-pane .form {
  justify-self: center;
  width: 330px;
}

.validation-pane .form .spacer {
  margin-bottom: 6px;
}

.validation-pane label {
  font-size: 22px;
  line-height: 28px;
}

.validation-pane input {
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  -webkit-appearance: none;
  background: transparent;
  color: inherit;
  padding: 11px;
  font-weight: 600;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 4px;
  width: 100%;
  cursor: text;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
}

.validation-pane .form button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  font: inherit;
  text-decoration: none;
  margin: 0;
  background: transparent;
  overflow: visible;
  text-transform: none;
  border: 2px solid #777777;
  padding: 4px 22px;
  font-size: 18px;
  line-height: 24px;
  background-color: #777777;
  appearance: auto;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: #f8f8f8;
  letter-spacing: normal;
  word-spacing: normal;

  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-width: none;
  transition-property: color, background-color, border-color, box-shadow;
  flex: 1 0 auto;
  text-align: center;
  text-indent: 0px;
  text-shadow: none;
  align-items: flex-start;
  font-size: 18px;
  line-height: 24px;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: #f8f8f8;
  letter-spacing: normal;
}

/* Used in ButtonToggleMode component*/

button.toggle-mode {
  width: 190px;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
  background-color: var(--dark-grey);
  cursor: pointer;
  color: var(--white);

  display: flex;
  align-items: center;

  border-width: 0;
}

.toggle-mode .label-element {
  font-size: 18px;
  padding: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.label-element.left-border {
  border-left: 4px solid var(--white);
  padding-left: 20px;
  padding-top: 15px;
}

/* Used for SpinnerLoading */

.validation-loading {
  margin-left: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.validation-loading:after {
  content: ' ';
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: validation-loading 1.2s linear infinite;
}

@keyframes validation-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Used for react-transition-group */

.editor-enter {
  opacity: 0;
  /* transform: scale(0.9); */
}
.editor-enter-active {
  opacity: 1;
  /* transform: translateX(0); */
  transition: opacity 150ms;
}
.editor-exit {
  opacity: 1;
}
.editor-exit-active {
  opacity: 0;
  /* transform: scale(0.9); */
  transition: opacity 150ms;
}

section.results {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results-display {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 650px !important;
  max-width: min(95vw, 220%);
  padding: 5px;
}

.results-display.results-display-error {
  border: 2px solid var(--error);
}

.results-display.results-display-success {
  border: 2px solid var(--success);
}

.results-display .results-display-title {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.results-display-icon {
  margin-right: 15px;
  padding-top: 5px;
}

.results-display-title h2 {
  margin-top: 14px;
  margin-bottom: 14px;
}

.results-display-body {
  padding: 5px;
}

.results-display-body h3 {
  margin: 12px auto 5px auto;
}

.results-display-body p {
  margin-left: 15px;
}

.results-display-body ol {
  padding-left: 35px;
}

.monaco-editor {
  padding-top: 10px;
  height: calc(100vh - 65px - 28px) !important;
}
